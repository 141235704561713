var fastSafeStringify = require('fast-safe-stringify')
var copy = require('clipboard-copy')

function tryStringify (obj) {
  try {
    return JSON.stringify(obj)
  } catch (e) {}
}

function stateCopy (obj) {
  var str = tryStringify(obj) || fastSafeStringify(obj)
  copy(str)
}

module.exports = stateCopy

module.exports = help

function help () {
  Object.defineProperty(window.choo, 'help', {
    get: get,
    set: noop
  })

  function get () {
    setTimeout(function () {
      print('copy', 'Serialize the current state to the clipboard.')
      print('debug', 'Enable Choo debug mode.')
      print('emit', 'Emit an event in the Choo emitter.')
      print('help', 'Print usage information.')
      print('log', 'Print the last 150 events emitted.')
      print('on', 'Listen for an event in the Choo emitter.')
      print('once', 'Listen for an event once in the Choo emitter.')
      print('perf', 'Print out performance metrics')
      print('state', 'Print the Choo state object.')
      print('storage', 'Print browser storage information.')
    }, 0)
    return 'Choo command overview'
  }
}

function print (cmd, desc) {
  var color = '#cc99cc'
  console.log('  %cchoo.' + cmd, 'color: ' + color, '— ' + desc)
}

function noop () {}

const choo = require('choo')
const html = require('choo/html')
const raw = require('choo/html/raw')
const devtools = require('choo-devtools')
const marked = require('marked')
const css = require('sheetify')
const fs = require('fs')

const app = choo()
const style = css('./app.css')

app.use(devtools())

app.use((state, emitter) => {
  // updateTitle()
  /*
  emitter.on('pushState', () => {
    updateTitle()
  })
  */

  emitter.on('DOMContentLoaded', () => {
    state.title = `GCC ~ DEA`
    state.atTexto = 'intro'
    emitter.emit(state.events.DOMTITLECHANGE, state.title)
    emitter.emit('render')
  })

  emitter.on('newTexto', (t) => {
    state.atTexto = t
    emitter.emit('render')
  })

  state.textos = {
    'intro': marked(fs.readFileSync('txt/intro.md').toString()),
    'primer': marked(fs.readFileSync('txt/primer.md').toString()),
    'semillas': marked(fs.readFileSync('txt/semillas.md').toString()),
    'workshop': marked(fs.readFileSync('txt/workshop.md').toString()),
    'lad~cad': marked(fs.readFileSync('txt/lad_cad.md').toString()),
    'outline': marked(fs.readFileSync('txt/outlineCharla_1.md').toString())
  }
})

app.route('/', main)
// app.route('/:article', main)

function main (state, emit) {
  /// if(!state.params.article)state.params.article = 'intro'
  return html`
  <body class=${style}>
    <header>
      <marquee direction='right'> Viernes 20-03 18hs ~ youtube de la DEA </marquee>
      <h1> ~ DEA/GCC ~ </h1>
    </header>
    <div id="wiki">
      <nav>
      ${Object.keys(state.textos).map(buildNav)}
      </nav>
      <div id="texto">
        ${raw(state.textos[state.atTexto])}
      </div>
    </div>
  </body>
  `
  function buildNav (el) {
    var selected = state.atTexto === el ? 'selected' : ''
    return html`
      <button onclick=${onclick} class=${selected}> ${el} </a>
      `
    function onclick (e) {
      emit('newTexto', e.target.innerText)
    }
  }
}

app.mount('body')
